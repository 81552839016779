<template>
  <v-app style="background-color:#f8f9fb;">
    <v-container style="background-color:#eee;" id="financeiro" fluid tag="section">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-link-variant" title="Consulta" color="rgb(71 130 180)"
            style="margin-top:60px !important; padding-top:10px !important; min-height: 70vh !important;"
            class="px-5 py-0">

            <v-form ref="form" lazy-validation>
              <v-row style="margin-top: 1rem; margin-left: 1rem;" class="d-flex align-center">
                <!-- Empresa -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-select v-model="consulta.empresa" :items="filteredEmpresas" label="Empresa" dense required
                    :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']" solo-inverted></v-select>
                </v-col>

                <!-- Data Inicial -->
                <v-col cols="5" sm="5" md="3" class="py-0">
                  <v-menu v-model="isStartDatePickerOpen" :close-on-content-click="false" transition="scale-transition"
                    offset-y bottom>
                    <template #activator="{ on }">
                      <v-text-field v-model="consulta.data_inicial" label="Data Inicial" dense solo-inverted readonly
                        required :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="localSelectedStartDateISO"
                      @input="handleStartDateSelection"></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Data Final -->
                <v-col cols="5" sm="5" md="3" class="py-0">
                  <v-menu v-model="isEndDatePickerOpen" :close-on-content-click="false" transition="scale-transition"
                    offset-y bottom>
                    <template #activator="{ on }">
                      <v-text-field v-model="consulta.data_final" label="Data Final" dense solo-inverted readonly
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="localSelectedEndDateISO"
                      @input="handleEndDateSelection"></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Botão Consultar (Alinhado à direita) -->
                <v-col cols="2" sm="5" md="3" class="py-0">
                  <v-btn small :loading="loadingConsulta" color="primary" @click="consultar_disparos()" class="pr-4">
                    <v-icon left>mdi-magnify</v-icon>Consultar
                  </v-btn>
                </v-col>
              </v-row>

            </v-form>
            <v-data-table style="margin-top: 1rem;" :headers="ranking_header" :items="listaDisparos"
              :items-per-page="50" items-per-page-text="Linhas por página" class="elevation-0">
              <br /> <br /> <br />
              <template v-slot:top>
                <v-progress-linear v-if="isLoading" indeterminate color="rgb(71 130 180)"></v-progress-linear>
              </template>
              <template v-slot:item="row">
                <tr :class="{ 'cor-row': row.index % 2 !== 0 }">

                  <!-- Empresa -->
                  <td class="linha" rowspan="1">
                    <v-chip :color="getStatusCor(row.item.empresa)" text-color="white">
                      {{ row.item.empresa }}
                    </v-chip>
                  </td>

                  <!-- Dados do Cliente -->
                  <td class="linha" rowspan="1">
                    <strong>Telefone:</strong> {{ row.item.telefone }} <br>
                    <strong>E-mail:</strong> {{ row.item.email }} <br>
                  </td>

                  <!-- Dados da Fatura -->
                  <td class="linha" rowspan="1">
                    <strong>Vencimento:</strong> {{ convertDate(row.item.dtVenc) }} <br>
                    <strong>Identificação:</strong> {{ row.item.identificacao }} <br>
                  </td>

                  <!-- Dados do Contrato -->
                  <td class="linha" rowspan="1">{{ row.item.contrato }}</td>

                  <!-- Status do Disparo -->
                  <td class="linha" rowspan="1">{{ convertDate(row.item.data_envio) }}</td>
                  <td class="linha" rowspan="1">
                    <v-tooltip bottom :color="getStatusDisparoCor(row.item.status)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip text-color="white" :color="getStatusDisparoCor(row.item.status)" v-bind="attrs"
                          v-on="on">
                          {{ row.item.status }}
                        </v-chip>
                      </template>
                      <span v-if="row.item.status === 'Enviado'">{{ row.item.mensagem }}</span>
                      <span v-else>{{ row.item.resposta }}</span>
                    </v-tooltip>
                  </td>

                </tr>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import VuetifyMoney from "./components/VuetifyMoney.vue";
import mensageria from '../../services/mensageria';

export default {
  name: 'MensageriaConsulta',
  components: {
    apexcharts:
      ContentLoader,
    "vuetify-money": VuetifyMoney
  },

  created() {
  },

  mounted() {
  },

  computed: {
    filteredEmpresas() {
      return this.empresas.filter(empresa => this.hasPermission(empresa));
    },
  },

  data() {
    return {
      loadingConsulta: false,
      listaDisparos: [
      ],
      consulta: {
        empresa: '',
        data_inicial: '',
        data_final: '',
      },
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,
      localSelectedStartDateISO: '',
      localSelectedEndDateISO: '',
      ranking_header: [
        { text: 'Empresa', value: 'empresa' },
        { text: 'Dados Cliente', value: 'dados_cliente' },
        { text: 'Dados Fatura', value: 'dados_fatura' },
        { text: 'Contrato', value: 'contrato' },
        { text: 'Data de Disparo', value: 'data_envio' },
        { text: 'Status', value: 'status' },
      ],
      isLoading: false,
      empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ"],
    }
  },

  methods: {
    getStatusCor(status) {
      switch (status) {
        case 'PARQUE DAS FLORES': return 'orange darken-2'
        case 'CEMITERIO PREVIDA': return 'blue lighten-1'
        case 'MEMORIAL PARQUE MACEIÓ': return 'green lighten-1'
        case 'PARQUE DO AGRESTE': return 'cyan lighten-1'
        case 'PREVIDA': return 'blue lighten-1'
        case 'A FLOR DO CAFÉ': return 'purple lighten-1'
        default: 'success'
      }
    },
    getStatusDisparoCor(status) {
      switch (status) {
        case 'Enviado': return 'green darken-1'
        case 'Falha': return 'red'
      }
    },
    getStatus(status) {
      switch (status) {
        case true: return 'ATIVO'
        case false: return 'DESATIVADO'
      }
    },
    handleStartDateSelection() {
      this.consulta.data_inicial = this.convertDate(this.localSelectedStartDateISO);
      this.isStartDatePickerOpen = false;
    },

    handleEndDateSelection() {
      this.consulta.data_final = this.convertDate(this.localSelectedEndDateISO);
      this.isEndDatePickerOpen = false;
    },
    convertDate(dateString, format = 'iso') {
      if (!dateString) return '';

      if (format === 'iso') {
        // Verifica se a string está no formato ISO completo (contém 'T')
        if (dateString.includes('T')) {
          dateString = dateString.split('T')[0]; // Remove a parte do horário
        }

        // Converte de ISO (YYYY-MM-DD) para BR (DD/MM/YYYY)
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      } else if (format === 'br') {
        // Converte de BR (DD/MM/YYYY) para ISO (YYYY-MM-DD)
        const [day, month, year] = dateString.split("/");
        return `${year}-${month}-${day}`;
      }

      return dateString; // Retorna a string original se o formato não for reconhecido
    },
    hasPermission(empresa) {
      this.user = JSON.parse(sessionStorage.getItem('user'));

      if (this.user.roles.includes("ROLE_ADMIN")) {
        return true;
      } else if (empresa === "Todas") {
        return true;
      } else {
        const requiredRoles = empresa
          .split(',')
          .map(name => 'ROLE_' + name.trim().toUpperCase().replace(/\s/g, '_'));

        const hasAnyRequiredRole = requiredRoles.some(role => this.user.roles.includes(role));
        return hasAnyRequiredRole;
      }
    },
    consultar_disparos() {
      this.loadingConsulta = true;

      if (!this.consulta.data_inicial || !this.consulta.data_final || !this.consulta.empresa) {
        this.loadingConsulta = false;
        return;
      }

      const dataInicial = this.convertDate(this.consulta.data_inicial, 'br');
      const dataFinal = this.convertDate(this.consulta.data_final, 'br');

      mensageria.lista_disparos(dataInicial, dataFinal, this.consulta.empresa)
        .then(response => {
          this.listaDisparos = response.data;
        })
        .catch(error => {
          console.error('Erro ao buscar disparos:', error);
        })
        .finally(() => {
          this.loadingConsulta = false;
        });
    }

  },
}

</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto !important;
  margin: -12px;
}

.v-menu__content {
  box-shadow: none !important;
  top: 4px !important;
}

.justify-center {
  justify-content: center;
}

.gray-row {
  background-color: rgb(71 130 180);
}

.cor-row {
  background-color: rgb(231 244 255);
  transition: none !important;
}

.linha {
  font-size: 11px !important;
  font-weight: normal;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.no-hover-effect.v-data-table tbody tr:hover {
  color: #3055a4;
}

.card-container {
  display: flex;
  justify-content: space-around;
}
</style>
